import React from "react";

const GiveAnswer = ({ questionPtOne, questionPtTwo, playerInputAt }) => {
  return (
    <div>
      <h1>
        {playerInputAt === "S" && "... "}
        {questionPtOne}{" "}
        {(playerInputAt === "M" && " ... ") ||
          (playerInputAt === "E" && " ...")}
        {questionPtTwo}
      </h1>
    </div>
  );
};

export default GiveAnswer;
