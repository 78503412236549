export const themeDark = "#0e0e10";
export const themeDark2 = "#18181b";
export const themeLighterDard = "#1f1f23";
export const themeAccentDark = "#26262c";
export const themeLightBlue = "#7395ae";
export const themeBlue = "#557a95";
export const themeBronze = "#b1a296";
export const themeError = "#fc4445";

// import {
//     themeDark,
//     themeDark2,
//     themeLighterDard,
//     themeAccentDark,
//     themeLightBlue,
//     themeBlue,
//     themeBronze,
//     themeError
//   } from "./colors";
