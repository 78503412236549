import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import {
  themeDark,
  themeDark2,
  themeAccentDark,
  themeBlue,
  themeBronze,
  themeError,
} from "../../colors";
import { Container } from "../../Container";
import { registerUrl } from "../../ENDPOINTS";
import { useDispatch } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { mobile } from "../../responsive";

const Wrapper = styled.div`
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    paddingTop: "100px",
    minWidth: "100%",
    overflow: "auto",
    display: "block",
  })}
`;
const Form = styled.div`
  overflow: auto;
  min-width: 70%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ minWidth: "100%" })}
`;

const Input = styled.input`
  text-align: center;
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  font-size: 16px;
  border: 1px solid ${(props) => props.borderColor};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  ${mobile({ width: "60%" })}
`;
const ErrorMessage = styled.p`
  color: ${themeError};
`;
const Redirect = styled.p`
  color: ${themeBlue};
  font-size: 12;
  margin-top: 10px;
  text-align: center;
  ${mobile({ marginBottom: "100px" })}
`;
const Button = styled.button`
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 90%;
  font-size: 18px;
  border: 1px solid ${themeAccentDark};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  ${mobile({ width: "65%" })}
`;

const Register = () => {
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password1Error, setPassword1Error] = useState("");
  const [password2Error, setPassword2Error] = useState("");
  const [otherErrors, setOtherErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleRegister = async (e) => {
    e.preventDefault();
    setOtherErrors("");
    dispatch(loginStart());
    setLoading(true);
    console.log(navigator.language);
    try {
      const res = await axios.post(
        `${registerUrl}`,
        {
          username: login,
          email,
          password1,
          password2,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": navigator.language,
          },
        }
      );
      console.log(res.data);
      dispatch(loginSuccess(res.data));
    } catch (err) {
      dispatch(loginFailure());
      const error = err.response.data;
      {
        error.username && setUsernameError(error.username[0]);
      }
      {
        error.email && setEmailError(error.email[0]);
      }
      {
        error.password1 && setPassword1Error(error.password1[0]);
      }
      {
        error.password2 && setPassword2Error(error.password2[0]);
      }
      {
        error.non_field_errors && setOtherErrors(error.non_field_errors[0]);
      }
      console.log("err.response.data: ", err.response.data);
    }
    setLoading(false);
  };

  const handleOnChangeLogin = (value) => {
    setLogin(value);
    setUsernameError("");
  };
  const handleOnChangeEmail = (value) => {
    setEmail(value);
    setEmailError("");
  };
  const handleOnPassword1 = (value) => {
    setPassword1(value);
    setPassword1Error("");
  };
  const handleOnPassword2 = (value) => {
    setPassword2(value);
    setPassword2Error("");
  };

  return (
    <Container>
      <Wrapper>
        <Form>
          <Input
            borderColor={usernameError ? themeError : "black"}
            placeholder="Login"
            onChange={(e) => handleOnChangeLogin(e.target.value)}
          />
          {usernameError && <ErrorMessage>{usernameError}</ErrorMessage>}
          <Input
            borderColor={emailError ? themeError : "black"}
            placeholder="Email"
            onChange={(e) => handleOnChangeEmail(e.target.value)}
          />
          {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          <Input
            borderColor={password1Error ? themeError : "black"}
            type="password"
            placeholder="Password"
            onChange={(e) => handleOnPassword1(e.target.value)}
          />
          {password1Error && <ErrorMessage>{password1Error}</ErrorMessage>}
          <Input
            borderColor={password2Error ? themeError : "black"}
            type="password"
            placeholder="Confirm Password"
            onChange={(e) => handleOnPassword2(e.target.value)}
          />
          {password2Error && <ErrorMessage>{password2Error}</ErrorMessage>}
          {otherErrors && <ErrorMessage>{otherErrors}</ErrorMessage>}
          {loading ? (
            <BounceLoader color={themeBlue} size={100} />
          ) : (
            <Button onClick={handleRegister}>Register</Button>
          )}
        </Form>
        <Link to="/login/" style={{ textDecoration: "none" }}>
          <Redirect>Already have an account</Redirect>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Register;
