import React from "react";
import styled from "styled-components";

const Alert = styled.div`
  background-color: red;
  padding: 20px 40px;
  min-width: 420px;
  position: absolute;
  right: 0px;
  top: 10px;
  border-radius: 4px;
  border-left: 8px solid #ffa502;
`;
const Message = styled.span`
  padding: 0 20px;
  font-size: 18px;
  color: pink;
`;

const Notification = () => {
  return (
    <Alert>
      <Message>Notification</Message>
    </Alert>
  );
};

export default Notification;
