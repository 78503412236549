import { DEBUG } from "./Debug";
// DEVELOPMENT true/false
const development = DEBUG;

// BASIC URL
export const BASIC_URL = development
  ? "http://127.0.0.1:8000"
  : "https://ridiline.com";

//WEBSOCKETS
export const websocketUrl = development
  ? "ws://127.0.0.1:8000/ws/websocket/"
  : "wss://ridiline.com:8001/ws/websocket/";

//PUBLIC REQUESTS
export const LoginUrl = `${BASIC_URL}/auth/login/`;
export const registerUrl = `${BASIC_URL}/auth/registration/`;
export const logoutUrl = `${BASIC_URL}/auth/logout/`;
export const editorData = `${BASIC_URL}/api/editor-data/`;
export const createProTextAudio = `${BASIC_URL}/api/create-pro-text-with-audio/`;
export const acceptTextToSpeech = `${BASIC_URL}/api/accept-text-to-speech/`;
export const deleteTextToSpeech = `${BASIC_URL}/api/delete-text-to-speech/`;
export const deleteAudioFileOnly = `${BASIC_URL}/api/delete-text-to-speech-audio-only/`;
export const createGame = `${BASIC_URL}/api/create-game/`;
export const joinGame = `${BASIC_URL}/api/join-game/`;
export const dataOnJoin = `${BASIC_URL}/api/data-on-join/`;
export const joinByJoinCode = `${BASIC_URL}/api/join-by-join-code/`;
export const prejoin = `${BASIC_URL}/api/prejoin/`;
export const languageRequest = `${BASIC_URL}/api/language/`;
export const allowMeToJoin = `${BASIC_URL}/api/prejoin-game-data/`;
export const getVoicesAvalible = `${BASIC_URL}/api/get-voices-avalible/`;
export const elevenlabsTTS = `${BASIC_URL}/api/elevenlavs-tts/`;
export const noAudioRepresentation = `${BASIC_URL}/api/no-audio-representation/`;
export const preAutomationTTS = `${BASIC_URL}/api/automation-pre-tts-set-up/`;

//PRIVATE REQUESTS
