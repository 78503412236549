import React, { useState, useEffect } from "react";

const CountDown = ({ milliSeconds }) => {
  const [displayTime, setDisplayTime] = useState(null);
  var intervarId = null;
  useEffect(() => {
    intervarId = setInterval(() => {
      updateRemainingTime();
    }, 1000);
    return () => clearInterval(intervarId);
  }, [milliSeconds]);
  const updateRemainingTime = () => {
    //const countDate = new Date().getTime() + 1000 * 50;
    const now = new Date().getTime(); //getTime = time in milisec
    const gap = milliSeconds - Math.floor(now / 1000);
    setDisplayTime(gap);
    //console.log(gap);
    if (gap < 1) {
      setDisplayTime(null);
      clearInterval(intervarId);
    }
  };
  return <> {displayTime}</>;
};

export default CountDown;

// ### CLEAN TIMER ###
// import React, { useState, useEffect } from "react";

// const CountDown = () => {
//   const [time, setTime] = useState(new Date().getTime() + 1000 * 5); // time from server
//   const [displayTime, setDisplayTime] = useState(null);
//   var indervarId = null;
//   useEffect(() => {
//     indervarId = setInterval(() => {
//       updateRemainingTime();
//     }, 1000);
//     return () => clearInterval(indervarId);
//   }, []);
//   const updateRemainingTime = () => {
//     //const countDate = new Date().getTime() + 1000 * 50;
//     const now = new Date().getTime(); //getTime = time in milisec
//     const gap = time - now;
//     setDisplayTime(Math.floor(gap / 1000));
//     if (Math.floor(gap / 1000) < 1) {
//       console.log(Math.floor(gap / 1000));
//       clearInterval(indervarId);
//     }
//   };
//   return <div>CountDow {displayTime}</div>;
// };

// export default CountDown;
