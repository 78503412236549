import axios from "axios";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AdBanner from "../Ads/AdBanner";
import { themeBlue, themeDark } from "../colors";
import { Container } from "../Container";
import { joinByJoinCode } from "../ENDPOINTS";
import { mobile } from "../responsive";
import { useEffect } from "react";

const Wrapper = styled.div`
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    paddingTop: "100px",
    minWidth: "100%",
    overflow: "auto",
    display: "block",
  })}
`;

const ForUsers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Option = styled.div`
  height: 150px;
  width: 150px;
  background-color: ${themeBlue};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
`;

const JoinSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const JoinCodeInput = styled.input`
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  ////
  height: 150px;
  width: 150px;
  background-color: ${themeBlue};
  border-radius: 50%;
  ::placeholder {
    color: white;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white;
  }
`;

const Home = () => {
  const { currentUser } = useSelector((state) => state);
  const [requestLoading, setRequestLoading] = useState(false);
  const [wrongCodeError, setWrongCodeError] = useState(false);
  const [joinCode, setJoinCode] = useState("");

  const navigate = useNavigate();

  const joinGameByJoinCodeRequest = async () => {
    setRequestLoading(true);
    var auth_token = null;
    if (currentUser != null) {
      auth_token = `token ${currentUser.token}`;
    }
    try {
      const res = await axios.post(
        `${joinByJoinCode}`,
        { joinCode: joinCode },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
            Authorization: auth_token,
          },
        }
      );
      console.log(res.data.gamePk);
      if (res.data.gamePk) {
        navigate(`/game/${res.data.gamePk}/`);
      }
    } catch (err) {
      console.log(err);
      setWrongCodeError("Błędny kod!");
    }
    setRequestLoading(false);
  };

  return (
    <Container>
      <Wrapper>
        <ForUsers>
          <JoinSection>
            <JoinCodeInput
              value={joinCode}
              onChange={(e) => {
                setJoinCode(e.target.value);
                setWrongCodeError("");
              }}
              placeholder="Kod dołączenia"
            />
            {joinCode && (
              <Option onClick={joinGameByJoinCodeRequest}>Dołącz!</Option>
            )}
          </JoinSection>
          {wrongCodeError && <h2>{wrongCodeError}</h2>}
          <Link to="create/" style={{ textDecoration: "none" }}>
            <Option>Stwórz grę</Option>
          </Link>
        </ForUsers>
      </Wrapper>
    </Container>
  );
};

export default Home;
