import React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  themeDark,
  themeDark2,
  themeLighterDard,
  themeAccentDark,
  themeLightBlue,
  themeBlue,
  themeBronze,
} from "../colors";
import { logout } from "../redux/userSlice";
const Container = styled.div`
  height: 59px;
  background-color: ${themeDark};
  border-bottom: solid 1px ${themeAccentDark};
`;
const Wrapper = styled.div`
  background-color: ${themeDark2};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Left = styled.div`
  flex: 1;
  background-color: red;
`;
const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
`;
const Logo = styled.h1`
  cursor: pointer;
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
`;
const MenuItem = styled.div`
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
`;
const Navbar = () => {
  const { currentUser } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleLogout = async (e) => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <Container>
      <Wrapper>
        <Left></Left>

        <Center>
          <Logo>
            <Link to="/" style={{ textDecoration: "none" }}>
              <span style={{ color: themeBlue }}>Ri</span>
              <span style={{ color: themeBronze }}>di</span>
              line
            </Link>
          </Logo>
        </Center>
        {/* {currentUser ? (
          <Right>
            <MenuItem>{currentUser.user.username}</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Right>
        ) : (
          <Right>
            <Link to="register/" style={{ textDecoration: "none" }}>
              <MenuItem>Register</MenuItem>
            </Link>
            <Link to="login/" style={{ textDecoration: "none" }}>
              <MenuItem>Sign In</MenuItem>
            </Link>
          </Right>
        )} */}
        <Right>
          {currentUser && <MenuItem onClick={handleLogout}>Logout</MenuItem>}
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
