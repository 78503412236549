import React from "react";
import styled from "styled-components";
import { themeAccentDark, themeBlue, themeBronze } from "../colors";

const Info = styled.h2`
  padding: 7px;
`;
const Answer = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  //
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid ${themeBronze} 1px;
  font-size: 20px;
  padding: 7px;
  :hover {
    outline: none !important;
    border-color: ${themeBlue};
    background-color: ${themeAccentDark};
    transition: 0.3s;
    transition-timing-function: ease-in-out;
  }
  cursor: pointer;
`;

const Answers = ({ answers, SendAction, playerPk }) => {
  return (
    <>
      <Info>Answers:</Info>
      {answers.map((answer) => {
        return (
          <Answer
            key={answer.pk}
            onClick={() =>
              SendAction(
                { answer_pk: answer.pk, player_pk: playerPk },
                "submit_answer"
              )
            }
          >
            {answer.text}
          </Answer>
        );
      })}
    </>
  );
};

export default Answers;
