import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import styled from "styled-components";
import {
  themeDark,
  themeDark2,
  themeAccentDark,
  themeBlue,
  themeBronze,
  themeError,
} from "../../colors";
import { Container } from "../../Container";
import { createGame } from "../../ENDPOINTS";
import { mobile } from "../../responsive";

const Wrapper = styled.div`
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ minWidth: "100%" })}
`;
const Form = styled.form`
  min-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ minWidth: "100%" })}
`;
const Input = styled.input`
  text-align: center;
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  font-size: 16px;
  border: 1px solid ${(props) => props.borderColor};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  ${mobile({ width: "60%" })}
`;
const Select = styled.select`
  text-align: center;
  background-color: ${themeDark2};
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  font-size: 16px;
  border: 1px solid ${(props) => props.borderColor};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  ${mobile({ width: "65%" })}
`;
const Option = styled.option`
  background-color: ${themeDark2};
  padding: 20px;
  margin: 20px;
  width: 90%;
  font-size: 26px;
`;
const Button = styled.button`
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 90%;
  font-size: 18px;
  border: 1px solid ${themeAccentDark};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  ${mobile({ width: "65%" })}
`;
const Info = styled.h1`
  margin-top: 20px;
`;

const Create = () => {
  const { currentUser } = useSelector((state) => state);
  const [nickname, setNickname] = useState("");
  const [guestAuthToken, setGuestAuthToken] = useState("");
  const [gamePassword, setGamePassword] = useState("");
  const [gameLanguage, setGameLanguage] = useState("pl"); // same as on select!
  const [languageOptions, setLanguageOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  //const { t } = useTranslation();

  const navigate = useNavigate();

  const getLanguagesAvalible = async () => {
    setLoading(true);
    console.log(currentUser);
    try {
      var auth_token = null;
      if (currentUser != null) {
        auth_token = `token ${currentUser.token}`;
      }
      const res = await axios.get(`${createGame}`, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": "pl",
          Authorization: auth_token,
        },
      });
      console.log(res.data.data);
      setLanguageOptions(res.data.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    var guestToken = window.localStorage.getItem("guest_game_auth_token");
    var lastTimeUsedNickname = window.localStorage.getItem("game_nickname");
    if (currentUser === null) {
      setGuestAuthToken(guestToken);
      setNickname(lastTimeUsedNickname);
    }
    getLanguagesAvalible();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    var auth_token = null;
    if (currentUser != null) {
      auth_token = `token ${currentUser.token}`;
    }
    try {
      const res = await axios.post(
        `${createGame}`,
        {
          nickname,
          gamePassword,
          gameLanguage,
          guestAuthToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
            Authorization: auth_token,
          },
        }
      );

      if (currentUser === null) {
        window.localStorage.setItem(
          "guest_game_auth_token",
          res.data.guest_game_auth_token
        );
        window.localStorage.setItem("game_nickname", res.data.nickname);
      }
      navigate(`/game/${res.data.game_pk}/`, {
        state: { GuestCreator: res.data.canconnect },
      });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Wrapper>
        {loading ? (
          <>
            <BounceLoader color={themeBlue} size={100} />
            <Info>Creating game</Info>
          </>
        ) : (
          <Form>
            {!currentUser && (
              <Input
                value={nickname}
                placeholder="Nickname"
                onChange={(e) => setNickname(e.target.value)}
              />
            )}
            <Input
              placeholder="password"
              value={gamePassword}
              type="password"
              onChange={(e) => setGamePassword(e.target.value)}
            />
            <Select
              defaultValue="pl" // same as in use state - language!
              onChange={(e) => setGameLanguage(e.target.value)}
            >
              {languageOptions.map((lang) => {
                return <Option label={lang.language} value={lang.shortcut} />;
              })}
              {/* <Option label="Polski" value="pl" />
              <Option label="Angielski" value="en" />
              <Option label="Niemiecki" value="de" /> */}
            </Select>
            {/* <Button onClick={handleSubmit}>{t("create_game")}</Button> */}
            <Button onClick={handleSubmit}>Create Game</Button>
          </Form>
        )}
      </Wrapper>
    </Container>
  );
};

export default Create;
