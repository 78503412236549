import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { themeAccentDark, themeBlue, themeBronze } from "../colors";
import SpeakerIcon from "@mui/icons-material/Speaker";
import DoneIcon from "@mui/icons-material/Done";

const Info = styled.h2`
  padding: 7px;
`;

const Container = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  //
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid ${themeBronze} 1px;
  font-size: 14px;
  padding: 7px;
  :hover {
    outline: none !important;
    border-color: ${themeBlue};
    background-color: ${themeAccentDark};
    transition: 0.3s;
    transition-timing-function: ease-in-out;
  }
  cursor: pointer;
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const OnlineIndicator = styled.div`
  background-color: ${(props) => props.online};
  height: 7px;
  width: 7px;
  border-radius: 50%;
`;
const Nickname = styled.h3`
  overflow: hidden;
  max-width: 180px;
  margin-left: 5px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Points = styled.div`
  margin-right: 7px;
  margin-left: 7px;
`;
const Sended = styled.div``;
const BlueText = styled.span`
  color: ${themeBlue};
`;
const Options = styled.div`
  //position: absolute; IT IS IT@!@!!@!@@!@
  max-height: ${(props) => props.height};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const Player = ({ players, gameState, SendAction, playerPk, isOwner }) => {
  const [toggleOptions, setToggleOptions] = useState(false);
  const [currentPlayerOptions, setCurrentPlayerOptions] = useState(null);

  const optionsRef = useRef(null);

  //console.log(players.sort((a, b) => (a.player_pk < b.player_pk ? 1 : -1))); // DESCENDING

  const onClickSetter = (playerPk) => {
    setToggleOptions(!toggleOptions);
    setCurrentPlayerOptions(playerPk);
    // if (isOwner) {
    //   console.log("LENGTH: ", optionsRef.current.children.length); // WE GOT NUMBER OF ELEMENTS
    // }
  };

  return (
    <>
      <Info>Players: {gameState}</Info>
      {players.map((player) => {
        //if(smtn) bla bla return ,,,
        return (
          <>
            <Container
              key={player.player_pk}
              onClick={() => onClickSetter(player.player_pk)}
            >
              <Left>
                <OnlineIndicator
                  online={player.online ? "lightgreen" : "grey"}
                />
                {player.audio_host && <SpeakerIcon />}
                <Nickname>
                  {player.is_owner ? (
                    <BlueText>{player.player_name}</BlueText>
                  ) : (
                    player.player_name
                  )}
                </Nickname>
              </Left>
              <Right>
                {gameState === "GB" && (
                  <Sended
                    style={{
                      visibility: player.answer_submitted_v2
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <DoneIcon size={12} />
                  </Sended>
                )}
                {gameState === "GA" && (
                  <Sended
                    style={{
                      visibility: player.answer_submitted
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    <DoneIcon size={12} />
                  </Sended>
                )}
                <Points>{gameState !== "NS" && player.points}</Points>
              </Right>
            </Container>
            {isOwner && (
              <Options
                ref={optionsRef}
                key={player.player_pk + "2"}
                height={
                  currentPlayerOptions === player.player_pk && toggleOptions
                    ? "170px"
                    : "0px"
                }
              >
                <Container
                  onClick={() =>
                    SendAction(
                      { playerPk, audio_hostPk: player.player_pk },
                      "audio_host"
                    )
                  }
                >
                  Audio Host
                </Container>
                <Container>Mute</Container>
                {!player.is_owner && <Container>Give Owner</Container>}
                <Container>Kick Out</Container>
                <Container>Ban</Container>
              </Options>
            )}
          </>
        );
      })}
    </>
  );
};

export default Player;
