import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  themeDark,
  themeDark2,
  themeAccentDark,
  themeBlue,
  themeBronze,
  themeError,
} from "../../colors";
import { LoginUrl } from "../../ENDPOINTS";
import { loginFailure, loginStart, loginSuccess } from "../../redux/userSlice";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { mobile } from "../../responsive";
import { Container } from "../../Container";

const Wrapper = styled.div`
  overflow: auto;
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    paddingTop: "100px",
    minWidth: "100%",
    overflow: "auto",
    display: "block",
  })}
`;
const Form = styled.form`
  min-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ minWidth: "100%" })}
`;

const Input = styled.input`
  text-align: center;
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  font-size: 16px;
  ${mobile({ width: "60%" })}
  border: 1px solid ${(props) => props.borderColor};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
`;
const ErrorMessage = styled.p`
  color: ${themeError};
`;
const Redirect = styled.p`
  color: ${themeBlue};
  font-size: 12;
  margin-top: 10px;
  ${mobile({ marginBottom: "100px" })}
  text-align: center;
`;
const Button = styled.button`
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 90%;
  font-size: 18px;
  ${mobile({ width: "65%" })}
  border: 1px solid ${themeAccentDark};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
`;

const Login = (e) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    dispatch(loginStart());
    setLoading(true);
    try {
      const res = await axios.post(
        `${LoginUrl}`,
        {
          username: login,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
          },
        }
      );
      console.log(res.data);
      dispatch(loginSuccess(res.data));
    } catch (err) {
      dispatch(loginFailure());
      const errObj = err.response.data;
      {
        errObj.username && setError(errObj.username[0]);
      }
      {
        errObj.non_field_errors && setError(errObj.non_field_errors[0]);
      }
      {
        errObj.password && setError(errObj.password[0]);
      }
    }
    setLoading(false);
  };
  const handleOnChangeLogin = (value) => {
    setLogin(value);
    setError("");
  };

  const handleOnChangePassword = (value) => {
    setPassword(value);
    setError("");
  };

  return (
    <Container>
      <Wrapper>
        <Form>
          <Input
            borderColor={error ? themeError : "black"}
            placeholder="Login or Email"
            onChange={(e) => handleOnChangeLogin(e.target.value)}
          />
          <Input
            borderColor={error ? themeError : "black"}
            type="password"
            placeholder="Password"
            onChange={(e) => handleOnChangePassword(e.target.value)}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {loading ? (
            <BounceLoader color={themeBlue} size={100} />
          ) : (
            <Button onClick={handleLogin}>Sign In</Button>
          )}
        </Form>
        <Link to="/register/" style={{ textDecoration: "none" }}>
          <Redirect>Need an account?</Redirect>
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Login;
