import React, { useState } from "react";
import styled from "styled-components";
import { themeBlue, themeBronze, themeDark } from "../colors";
import GameOptions from "./GameOptions";
import VoiceOptions from "./VoiceOptions";

const Wrap = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StartButton = styled.button`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: ${themeDark};
  color: white;
  font-size: 24px;
  border: 1px solid ${themeBronze};
  margin-bottom: 10px;
  :hover {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
    transition: 0.4s;
    transition-timing-function: ease-in-out;
  }
`;
const BlueText = styled.span`
  color: ${themeBlue};
`;
// var xd = {
//   player_name: "editor",
//   player_pk: 147,
//   user_pk: 2,
//   is_owner: true,
//   online: true,
//   answer_submitted: false,
//   answer_submitted_v2: false,
//   points: 0,
// };

const NotStarted = ({
  isOwner,
  SendAction,
  players,
  userId,
  joinCode,
  gameTypeOptions,
  selectedGameOption,
  playerPk,
  handleGetVoicesAvalible,
  voices,
  selectedVoiceOption,
  noGameModeError,
  noVoiceError,
  readQuestionEveryTime,
}) => {
  return (
    <Wrap>
      {isOwner && (
        <GameOptions
          gameTypeOptions={gameTypeOptions}
          selectedGameOption={selectedGameOption}
          SendAction={SendAction}
          playerPk={playerPk}
          handleGetVoicesAvalible={handleGetVoicesAvalible}
          noGameModeError={noGameModeError}
        />
      )}
      {isOwner && voices && (
        <VoiceOptions
          voices={voices}
          selectedVoiceOption={selectedVoiceOption}
          SendAction={SendAction}
          playerPk={playerPk}
          noVoiceError={noVoiceError}
        />
      )}
      {players.map((player) => {
        if (player.is_owner && player.user_pk === userId && players.length > 2)
          // 1 for dev
          return (
            <StartButton onClick={() => SendAction("my message", "start")}>
              Start
            </StartButton>
          );
      })}
      {/* <StartButton onClick={() => SendAction("my message", "start")}>
        Start
      </StartButton> */}
      {players.length < 3 && <h2>At least 3 players needed</h2>}{" "}
      {/* 2 for dev */}
      {players.length > 2 && ( // 1 for dev
        <h2>
          Waiting for{" "}
          <BlueText>
            {players.map((player) => {
              if (player.is_owner) return player.player_name;
            })}
          </BlueText>{" "}
          to start!
        </h2>
      )}
      <h4>
        Kod dołączenia: <BlueText>{joinCode}</BlueText>
      </h4>
      {isOwner && (
        <>
          <h4>Read question every time</h4>
          <input
            type="checkbox"
            checked={readQuestionEveryTime}
            onChange={() =>
              SendAction(!readQuestionEveryTime, "set_read_question_every_time")
            }
          />
        </>
      )}
    </Wrap>
  );
};

export default NotStarted;
