import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import styled from "styled-components";
import {
  themeAccentDark,
  themeBlue,
  themeBronze,
  themeDark,
  themeDark2,
  themeError,
} from "../../colors";
import Answers from "../../components/Answers";
import CountDown from "../../components/CountDown";
import GameWebsocket from "../../components/GameWebsocket";
import GiveAnswer from "../../components/GiveAnswer";
import GiveBest from "../../components/GiveBest";
import NotStarted from "../../components/NotStarted";
import Player from "../../components/Player";
import Results from "../../components/Results";
import { Container } from "../../Container";
import {
  BASIC_URL,
  joinGame,
  dataOnJoin,
  getVoicesAvalible,
} from "../../ENDPOINTS";
import { websocketUrl } from "../../ENDPOINTS";

import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import { mobile } from "../../responsive";

const Wrapper = styled.div`
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ minWidth: "100%", overflow: "auto" })}
`;
const MainWrapper = styled.div`
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({ minWidth: "100%", overflow: "auto", display: "inline-block" })}
`;
const GameWrapperTop = styled.div`
  background-color: ${themeDark};
  min-width: 700px;
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid ${themeAccentDark} 1px;
  ${mobile({ minWidth: "100%" })}
`;
const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;
const VolumeHolder = styled.div``;

const GameWrapper = styled.div`
  width: 100%;
  height: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${mobile({
    flexDirection: "column",
    minWidth: "100%",
    display: "block",
    height: "auto",
  })}
`;
const LeftSection = styled.div`
  width: 40%;
  height: 100%;
  display: column;
  flex-direction: row;
  border-right: solid ${themeAccentDark} 1px;
  ${mobile({ width: "100%", height: "auto", borderRight: "none" })}
`;
const PlayersSection = styled.div`
  overflow: auto; //mobile - display: block ?? -bez overflow??
  width: 100%;
  //height: calc(50% - 1px);
  height: calc(${(props) => props.heighValue} - 1px);
  border-bottom: solid ${themeAccentDark} 1px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ${mobile({
    minHeight: "auto",
    // minHeight: "60vh",
    display: (props) => props.visibleOnMobile,
    overflow: "none",
  })}
`;
const AnswersSection = styled.div`
  overflow: auto; //mobile - display: block ?? -bez overflow??
  width: 100%;
  //height: calc(50% - 1px);
  height: calc(${(props) => props.heighValue} - 1px);
  border-bottom: solid ${themeAccentDark} 1px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ${mobile({
    minHeight: "auto",
    //minHeight: "60vh",
    display: (props) => props.visibleOnMobile,
    overflow: "none",
  })}
`;
const RightSection = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
  ${mobile({
    width: "100%",
    minHeight: "60vh",
    display: (props) => props.display,
    // HERE CHANGE DISPLAY BASE OF GAME STATE! flex - block
  })}
`;

const GiveBestSection = styled.div`
  overflow: auto; //mobile - display: block ?? -bez overflow??
  width: 100%;
  height: 100%;
  border-bottom: solid ${themeAccentDark} 1px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ${mobile({
    display: "block",
  })}
`;
const Question = styled.h3`
  padding: 5px 10px 0px 10px;
`;

const Hint = styled.p`
  padding: 0px 10px 5px 10px;
  color: grey;
  border-bottom: solid ${themeAccentDark} 1px;
`;

const ResultsSection = styled.div`
  overflow: auto; //mobile - display: block ?? -bez overflow??
  width: 100%;
  height: 100%;
  border-bottom: solid ${themeAccentDark} 1px;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  ${mobile({
    display: "block",
  })}
`;
const BlueText = styled.span`
  color: ${themeBlue};
`;
////////////////////////////DROPDOWN
const MenuContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 5px;
  background-color: ${themeDark2};
`;
const MenuTrigger = styled.div`
  padding: 3px;
  height: 100%;
  min-width: 200px;
  border-bottom: solid
    ${(props) => (props.error ? themeError : themeAccentDark)} 1px;
  transition: border-bottom 0.3s ease-in-out;
  :hover {
    outline: none !important;
    border-color: ${themeBlue};
    background-color: ${themeAccentDark};
    transition: 0.3s;
    transition-timing-function: ease-in-out;
  }
  cursor: pointer;
`;
const DropdownMenu = styled.div`
  width: 200px;
  position: absolute;
  max-height: ${(props) => props.height};
  overflow: auto;
  transition: max-height 0.3s ease-in-out;
  background-color: ${themeDark2};
`;

const DropdownItem = styled.div`
  margin: 3px;
  padding: 3px;
  font-size: 24px;
  border-bottom: solid ${themeAccentDark} 1px;
  :hover {
    outline: none !important;
    border-color: ${themeBlue};
    background-color: ${themeAccentDark};
    transition: 0.3s;
    transition-timing-function: ease-in-out;
  }
  cursor: pointer;
`;
const SelectGameMode = styled.p`
  color: ${(props) => props.color};
  transition: color 0.3s ease-in-out;
`;

const GameModeInfo = styled.p`
  margin-left: 5px;
`;

////////////////////////////DROPDOWN

const Game = ({ guestAuthToken, nickname }) => {
  const clientRef = useRef(null);
  const questionAudioRefPartOne = useRef(null);
  const questionAudioRefPartTwo = useRef(null);
  const answerAudioRef = useRef(null);
  const playerPk = useRef(null);
  const hearSound = useRef(true);
  const [volumeIcon, setVolumeIcon] = useState(true);
  //Websocket module
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { currentUser } = useSelector((state) => state);
  const params = useParams();

  // Game Options
  const [gameTypeMenu, setGameTypeMenu] = useState(false); // ON CLICK EVENT!
  const [gameTypeOptions, setGameTypeOptions] = useState([]);
  const [voices, setVoices] = useState(null);
  const [selectedGameOption, setSelectedGameOption] = useState(null);
  const [selectedVoiceOption, setSelectedVoiceOption] = useState(null);
  const [readQuestionEveryTime, setReadQuestionEveryTime] = useState(null);
  const [currentRound, setCurrentRound] = useState(0);
  const [numberOfRounds, setNumberOfRounds] = useState(10); // HARD CODED @@!!!@

  const gameStatesWithNoAnswersOnMobile = ["RA", "GB", "DR", "NS"];
  const rightSectionDisplay = ["GB", "DR"];

  const [isOwner, setIsOwner] = useState(false);
  const [joinCode, setJoinCode] = useState("");

  const [players, setPlayers] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [givenAnswers, setGivenAnswers] = useState([]);
  const [results, setResults] = useState([]);
  const [gameState, setGameState] = useState("NS");
  const [milliSeconds, setMilliSeconds] = useState(null);

  const [questionTextPartOne, setQuestionTextPartOne] = useState("");
  const [questionTextPartTwo, setQuestionTextPartTwo] = useState("");
  const [playerInputAt, setPlayerInputAt] = useState("");
  const [answerText, setAnswerText] = useState("");

  const [togglePlayers, setTogglePlayers] = useState(false);

  //ERRORS
  const [noGameModeError, setNoGameModeError] = useState(false);
  const [noVoiceError, setNoVoiceError] = useState(false);

  useEffect(() => {
    if (gameState === "NS") {
      players.sort((a, b) => (a.player_pk > b.player_pk ? 1 : -1));
    } else if (gameState === "SU" || gameState === "GA") {
      players.sort((a, b) => (a.points < b.points ? 1 : -1));
    }
  }, [gameState]);

  const UpdatePlayerAttribute = (key, value, player_pk_to_update) => {
    setPlayers((players) =>
      players.map((player) => {
        if (player.player_pk === player_pk_to_update) {
          if (key === "points") {
            value = player.points + value;
          }
          return { ...player, [key]: value }; //[is_owner] etc
        } else {
          return player;
        }
      })
    );
  };

  const setNewHost = (key, value, player_pk_to_update) => {
    setPlayers((players) =>
      players.map((player) => {
        if (player.player_pk === player_pk_to_update) {
          return { ...player, [key]: value };
        } else {
          return { ...player, [key]: false };
        }
      })
    );
  };

  const PlayersAttributeRoundReset = () => {
    setPlayers((players) =>
      players.map((player) => {
        return {
          ...player,
          answer_submitted_v2: false,
          answer_submitted: false,
        }; //[is_owner] etc
      })
    );
    setPlayerInputAt("");
    setQuestionTextPartOne("");
    setQuestionTextPartTwo("");
  };
  const unMuteMe = () => {
    //check if audio ref's are not null and load them@!
  };

  const getOnJoinData = async () => {
    var auth_token = null;
    if (currentUser != null) {
      auth_token = `token ${currentUser.token}`;
    }
    try {
      const res = await axios.post(
        `${dataOnJoin}`,
        {
          game_pk: params.slug,
          //user_pk: currentUser.user.id,
          guestAuthToken: guestAuthToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: auth_token,
          },
        }
      );
      console.log("DATA FROM getOnJoinData", res.data);
      if (gameState === "NS") {
        setGameState(res.data.game_state);
      }
      if (res.data.question_data) {
        if (res.data.question_data.player_input_at === "M") {
          questionSetter(
            res.data.question_data.part_one.question,
            res.data.question_data.part_one.url,
            res.data.question_data.player_input_at,
            res.data.question_data.part_two.question,
            res.data.question_data.part_two.url
          );
        } else {
          questionSetter(
            res.data.question_data.part_one.question,
            res.data.question_data.url,
            res.data.question_data.player_input_at
          );
        }
      }
      setAnswers(res.data.answers);
      setMilliSeconds(res.data.time);
      setGivenAnswers(res.data.display_given_answers);
      setResults(res.data.given_best_answer_results);
      setGameTypeOptions(res.data.game_types);
      setSelectedGameOption(res.data.game_mode);
      setCurrentRound(res.data.round);
      setJoinCode(res.data.join_code);
      setReadQuestionEveryTime(res.data.read_question_every_time);
      if (res.data.error) {
        console.log("ERROR", res.data.error);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  const questionSetter = (
    firstText,
    firstUrl,
    playerInputAt,
    secondText,
    secondUrl
  ) => {
    console.log("1ST URL: ", firstUrl);
    setQuestionTextPartOne(firstText);
    questionAudioRefPartOne.current = new Audio(BASIC_URL + firstUrl);
    if (hearSound.current) {
      console.log("LOADING QUESTION FILE!");
      questionAudioRefPartOne.current.load();
    }
    setPlayerInputAt(playerInputAt);
    if (playerInputAt === "M") {
      setQuestionTextPartTwo(secondText);
      questionAudioRefPartTwo.current = new Audio(BASIC_URL + secondUrl);
      if (hearSound.current) {
        questionAudioRefPartTwo.current.load();
      }
    }
  };

  useEffect(() => {
    if (waitingToReconnect) {
      return;
    }

    // Only set up the websocket once
    if (!clientRef.current) {
      if (currentUser === null) {
        var websocketToken = guestAuthToken;
      } else {
        var websocketToken = currentUser.user.game_auth_token;
      }
      const client = new WebSocket(
        `${websocketUrl}${params.slug}/?game_token=${websocketToken}`
      );
      clientRef.current = client;

      window.client = client;

      client.onerror = (e) => console.error(e);

      client.onopen = () => {
        setIsOpen(true);
        console.log("ws opened");
        getOnJoinData();
        //client.send("ping");
      };

      client.onclose = () => {
        if (clientRef.current) {
          // Connection failed
          console.log("ws closed by server");
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log("ws closed by app component unmount");
          return;
        }

        if (waitingToReconnect) {
          return;
        }

        // Parse event code and log
        setIsOpen(false);
        console.log("ws closed");

        // Setting this will trigger a re-run of the effect,
        // cleaning up the current websocket, but not setting
        // up a new one right away
        setWaitingToReconnect(true);

        // This will trigger another re-run, and because it is false,
        // the socket will be set up again
        setTimeout(() => setWaitingToReconnect(null), 5000);
      };
      client.onmessage = (message) => {
        console.log("received message", message.data);
        const res = JSON.parse(message.data);
        console.log(res);
        if (res.message.key === "players") {
          console.log(res.message.data);
          setPlayers(res.message.data);
          var audio_host_holder = res.message.data.find((player) => {
            return player.audio_host === true;
          });
          if (playerPk.current === null) {
            res.message.data.map((player) => {
              if (player.user_pk === nickname) {
                playerPk.current = player.player_pk;
                setIsOwner(player.is_owner);
                //CASUAL AUDIO SETTING
                if (audio_host_holder) {
                  hearSound.current = player.audio_host;
                  setVolumeIcon(player.audio_host);
                }
              }
            });
          }
        } else if (res.message.key === "game_state") {
          setGameState(res.message.data);
          if (res.message.data == "NS") {
            setCurrentRound(0);
          }
        } else if (res.message.key === "set_question") {
          if (res.message.data.player_input_at === "M") {
            questionSetter(
              res.message.data.part_one.question,
              res.message.data.part_one.url,
              res.message.data.player_input_at,
              res.message.data.part_two.question,
              res.message.data.part_two.url
            );
          } else {
            questionSetter(
              res.message.data.part_one.question,
              res.message.data.part_one.url,
              res.message.data.player_input_at
            );
          }
        } else if (res.message.key === "play_question_part_one") {
          if (hearSound.current && questionAudioRefPartOne.current !== null) {
            questionAudioRefPartOne.current.play();
          }
        } else if (res.message.key === "play_question_part_two") {
          if (hearSound.current && questionAudioRefPartTwo.current !== null) {
            questionAudioRefPartTwo.current.play();
          }
        } else if (res.message.key === "set_answer") {
          setAnswerText(res.message.data.text);
          answerAudioRef.current = new Audio(BASIC_URL + res.message.data.url);
          if (hearSound.current) {
            answerAudioRef.current.load();
          }
        } else if (res.message.key === "play_answer") {
          if (hearSound.current) {
            answerAudioRef.current.play();
          }
        } else if (res.message.key === "left") {
          setPlayers((players) =>
            players.filter((player) => {
              return player.player_pk !== res.message.data;
            })
          );
        } else if (res.message.key === "offline") {
          UpdatePlayerAttribute("online", false, res.message.data);
        } else if (res.message.key === "answers") {
          console.log("ANSWER DATA: ", res.message.data);
          const playerAnswers = res.message.data.filter((obj) => {
            return obj.user_pk === nickname; //currentUser.user.id;
          });
          setAnswers(playerAnswers[0].answers);
        } else if (res.message.key === "player_answer") {
          UpdatePlayerAttribute(
            "answer_submitted",
            true,
            res.message.data.player_pk
          );
          console.log("playerPk: ", playerPk);
          console.log("player_pk from message: ", res.message.data.player_pk);
          if (playerPk.current === res.message.data.player_pk) {
            setAnswers((answers) =>
              answers.map((answer) => {
                if (answer.pk === res.message.data.old_answer_pk) {
                  console.log("NEW ANSWER: ", res.message.data.new_answer);
                  return res.message.data.new_answer;
                }
                return answer;
              })
            );
          }
        } else if (res.message.key === "given_answers") {
          console.log("GIVEN ANSWERS: ", res.message.data);
          setGivenAnswers(res.message.data);
        } else if (res.message.key === "state_v2") {
          console.log("VVVVVVVVVVVVVVVVVVVVVVVV V2: ", res.message.data);
          UpdatePlayerAttribute(
            "answer_submitted_v2",
            true,
            res.message.data.player_pk
          );
        } else if (res.message.key === "results") {
          setResults(res.message.data);
          res.message.data.map((player) => {
            UpdatePlayerAttribute("points", player.points, player.author_pk); // key, value, whom to
          });
        } else if (res.message.key === "round_clean_up") {
          PlayersAttributeRoundReset();
        } else if (res.message.key === "time") {
          setMilliSeconds(res.message.data);
        } else if (res.message.key === "audio_host") {
          setNewHost("audio_host", true, res.message.data);
          if (res.message.data === playerPk.current) {
            hearSound.current = true;
            setVolumeIcon(true);
          } else if (res.message.data === 0) {
            //res.message.data = 0 - Owner send the pk of current audio host
            //                        to remove the audio host, in response we recived 0
            hearSound.current = true;
            setVolumeIcon(true);
            // EVERYONE CAN HEAR NOW!
          } else {
            hearSound.current = false;
            setVolumeIcon(false);
          }
        } else if (res.message.key === "new_owner") {
          setNewHost("is_owner", true, res.message.data);
          if (res.message.data === playerPk.current) {
            setIsOwner(true);
          }
        } else if (res.message.key === "game_type") {
          setSelectedGameOption(res.message.data);
          setNoGameModeError(false);
        } else if (res.message.key === "round") {
          setCurrentRound(res.message.data);
        } else if (res.message.key === "error") {
          if (res.message.data === "no_game_type") {
            setNoGameModeError(true);
          } else if (res.message.data === "no_voice") {
            setNoVoiceError(true);
          }
        } else if (res.message.key === "game_voice") {
          setSelectedVoiceOption(res.message.data);
          setNoVoiceError(false);
        } else if (res.message.key === "read_question_every_time") {
          setReadQuestionEveryTime(res.message.data);
          console.log("read_question_every_time: ", res.message.data);
        }
      };
      return () => {
        console.log("Cleanup");
        // Dereference, so it will set up next time
        clientRef.current = null;

        client.close();
      };
    }
  }, [waitingToReconnect]);

  const handleGetVoicesAvalible = async (id) => {
    try {
      const res = await axios.get(`${getVoicesAvalible}${id}/0/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: null,
        },
      });
      console.log(res.data);
      if (res.data.success) {
        if (res.data.voices) {
          setVoices(res.data.voices);
        } else {
          setVoices(null);
        }
      } else {
      }
    } catch (err) {}
  };

  const SendAction = (message, type) => {
    //console.log("SENDING");
    clientRef.current.send(JSON.stringify({ message, type }));
  };

  return (
    <Container>
      {/* <CountDown milliSeconds={milliSeconds} /> */}
      {!isOpen || waitingToReconnect ? (
        <Wrapper>
          <BounceLoader color={themeBlue} size={100} />
          <h1>Connecting</h1>
        </Wrapper>
      ) : (
        <MainWrapper>
          <GameWrapperTop>
            <Left>
              <p>BETA!@</p>
            </Left>
            <Right>
              <CountDown milliSeconds={milliSeconds} />
              <VolumeHolder
                onClick={() => {
                  console.log(hearSound.current);
                  hearSound.current = !volumeIcon;
                  setVolumeIcon(!volumeIcon);
                }}
              >
                {volumeIcon ? <VolumeUpIcon /> : <VolumeOffIcon />}
              </VolumeHolder>
            </Right>
          </GameWrapperTop>
          <GameWrapper>
            <LeftSection>
              <PlayersSection
                onClick={() => setTogglePlayers(!togglePlayers)}
                visibleOnMobile={() => {
                  if (isOwner) {
                    return "block";
                  }
                  return gameState === "RA" ? "none" : "block";
                }}
                heighValue={gameState === "NS" ? "100%" : "50%"}
              >
                {players && (
                  <Player
                    players={players}
                    gameState={gameState}
                    SendAction={SendAction}
                    isOwner={isOwner}
                    playerPk={playerPk.current}
                  />
                )}
              </PlayersSection>
              <AnswersSection
                visibleOnMobile={
                  gameStatesWithNoAnswersOnMobile.includes(gameState)
                    ? "none"
                    : "block"
                }
                heighValue={gameState === "NS" ? "0%" : "50%"}
              >
                <Answers
                  answers={answers}
                  SendAction={SendAction}
                  playerPk={playerPk.current}
                />
              </AnswersSection>
            </LeftSection>
            <RightSection
              display={
                rightSectionDisplay.includes(gameState) ? "block" : "flex"
              }
            >
              {gameState === "NS" && (
                <NotStarted
                  isOwner={isOwner}
                  players={players}
                  SendAction={SendAction}
                  userId={nickname}
                  joinCode={joinCode}
                  gameTypeOptions={gameTypeOptions}
                  selectedGameOption={selectedGameOption}
                  voices={voices}
                  selectedVoiceOption={selectedVoiceOption}
                  playerPk={playerPk}
                  handleGetVoicesAvalible={handleGetVoicesAvalible}
                  noGameModeError={noGameModeError}
                  noVoiceError={noVoiceError}
                  readQuestionEveryTime={readQuestionEveryTime}
                />
              )}
              {gameState === "ST" && (
                <h1>GAME WILL START SOON, CHECK OUT YOUR ANSWERS!</h1>
              )}
              {gameState === "GA" && (
                <GiveAnswer
                  questionPtOne={questionTextPartOne}
                  questionPtTwo={questionTextPartTwo}
                  playerInputAt={playerInputAt}
                />
              )}
              {gameState === "RA" && (
                <h1>
                  {playerInputAt === "S" && <BlueText>{answerText}</BlueText>}{" "}
                  {questionTextPartOne}{" "}
                  {(playerInputAt === "M" && (
                    <BlueText>{answerText}</BlueText>
                  )) ||
                    (playerInputAt === "E" && (
                      <BlueText>{answerText}</BlueText>
                    ))}{" "}
                  {questionTextPartTwo}
                </h1>
              )}
              {gameState === "GB" && (
                <GiveBestSection>
                  <Question>
                    {questionTextPartOne && questionTextPartOne}
                    {questionTextPartTwo && <>... {questionTextPartTwo}</>}
                  </Question>
                  <Hint>Select the best one:</Hint>
                  <GiveBest
                    givenAnswers={givenAnswers}
                    playerPk={playerPk.current}
                    SendAction={SendAction}
                  />
                </GiveBestSection>
              )}
              {gameState === "DR" && (
                <ResultsSection>
                  <Question>
                    {questionTextPartOne && questionTextPartOne}
                    {questionTextPartTwo && <>... {questionTextPartTwo}</>}
                  </Question>
                  <Hint>Results:</Hint>
                  <Results results={results} />
                </ResultsSection>
              )}
              {gameState === "SU" && <h1>The End!</h1>}
            </RightSection>
          </GameWrapper>
        </MainWrapper>
      )}
    </Container>
  );
};

export default Game;
