import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getVoicesAvalible } from "../ENDPOINTS";
import { themeBlue, themeBronze, themeDark, themeDark2 } from "../colors";
import axios from "axios";

const Select = styled.select`
  text-align: center;
  background-color: ${themeDark2};
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  font-size: 16px;
  border: 1px solid ${(props) => props.borderColor};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
`;
const Option = styled.option`
  background-color: ${themeDark2};
  padding: 20px;
  margin: 20px;
  width: 90%;
  font-size: 26px;
`;

const QoraButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

const Button = styled.button`
  background-color: ${(props) => props.color || themeBronze};
  width: 40%;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
`;

const PlayerInputButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;

const ElevenLabsProperties = styled.input`
  width: 70%;
`;

const BoostCheckBox = styled.input``;

const PreEdit = ({
  gameTypes,
  currentGameType,
  setCurrentGameType,
  setActiveVoice,
  questionOrAnswer,
  setQuestionOrAnswer,
  setPlayerInputAt,
  playerInputAt,
  setVoiceProvider,
  voiceProvider,
  activeVoice,
  stability,
  setStability,
  similarity,
  setSimilarity,
  style,
  setStyle,
  boost,
  setBoost,
  dataToCheck,
  token,
  preAutomationTTSHandler,
  creatorOrAutomation,
  setCreatorOrAutomation,
  checkForNoAudio,
}) => {
  const [voiceMakersAvalible, setVoiceMakersAvalible] = useState();
  const [elevenLabsAvalible, setElevenLabsAvalible] = useState();
  //
  const voicemakerSelect = useRef();
  const elevenlabsSelect = useRef();

  const findVoice = (id, provider) => {
    setVoiceProvider(provider);
    if (provider === "voicemaker") {
      setActiveVoice(voiceMakersAvalible.find((voice) => voice.pk == id));
      if (elevenlabsSelect.current) {
        elevenlabsSelect.current.value = "default";
      }
    } else if (provider === "elevenlabs") {
      var voiceSearchResult = elevenLabsAvalible.find(
        (voice) => voice.pk == id
      );
      setActiveVoice(voiceSearchResult);
      setStability(voiceSearchResult.default_stability * 100);
      setSimilarity(voiceSearchResult.default_similarity_boost * 100);
      setStyle(voiceSearchResult.default_style * 100);
      setBoost(voiceSearchResult.use_speaker_boost);
      if (voicemakerSelect.current) {
        voicemakerSelect.current.value = "default";
      }
    }
  };

  const handleGetVoicesAvalible = async (id) => {
    try {
      const res = await axios.get(`${getVoicesAvalible}${id}/1/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
      });
      console.log(res.data);
      if (res.data.success) {
        let voicemaker_data = res.data.voicemaker_voices_data;
        let elevenLabs_data = res.data.eleven_labs_voices_data;
        if (voicemaker_data.length) {
          setVoiceMakersAvalible(voicemaker_data);
        } else {
          setVoiceMakersAvalible(null);
        }
        if (elevenLabs_data.length) {
          setElevenLabsAvalible(elevenLabs_data);
        } else {
          setElevenLabsAvalible(null);
        }
      } else {
      }
    } catch (err) {}
  };
  return (
    <>
      <h1>Creation mode</h1>
      <QoraButtons>
        <Button
          onClick={() => setCreatorOrAutomation("C")}
          color={creatorOrAutomation === "C" && themeBlue}
        >
          Creator
        </Button>
        <Button
          onClick={() => setCreatorOrAutomation("A")}
          color={creatorOrAutomation === "A" && themeBlue}
        >
          Automation
        </Button>
      </QoraButtons>
      <h1>Type</h1>
      <QoraButtons>
        <Button
          onClick={() => setQuestionOrAnswer("A")}
          color={questionOrAnswer === "A" && themeBlue}
        >
          Answer
        </Button>
        <Button
          onClick={() => setQuestionOrAnswer("Q")}
          color={questionOrAnswer === "Q" && themeBlue}
        >
          Question
        </Button>
      </QoraButtons>
      {questionOrAnswer === "Q" && (
        <>
          <h1> Player input at: </h1>
          <PlayerInputButtons>
            <Button
              onClick={() => setPlayerInputAt("S")}
              color={playerInputAt === "S" && themeBlue}
            >
              Start
            </Button>
            {/* onClick={() => handlePlayerInputAt("S")} */}
            <Button
              onClick={() => setPlayerInputAt("M")}
              color={playerInputAt === "M" && themeBlue}
            >
              Middle
            </Button>
            {/* onClick={() => handlePlayerInputAt("M")} */}
            <Button
              onClick={() => setPlayerInputAt("E")}
              color={playerInputAt === "E" && themeBlue}
            >
              End
            </Button>
            {/* onClick={() => handlePlayerInputAt("E")} */}
          </PlayerInputButtons>
        </>
      )}
      {gameTypes && (
        <>
          <h1>Current game mode</h1>
          <Select
            defaultValue={currentGameType}
            onChange={(e) => {
              setCurrentGameType(e.target.value);
              handleGetVoicesAvalible(e.target.value);
            }}
          >
            <Option label="select game type" value="default" disabled />
            {gameTypes.map((gameType) => {
              return (
                <Option
                  label={gameType.game_type_name}
                  value={gameType.game_type_pk}
                  key={gameType.game_type_pk}
                />
              );
            })}
          </Select>
        </>
      )}
      {voiceMakersAvalible && (
        <>
          <h1>VoiceMaker voices avalible:</h1>
          <Select
            ref={voicemakerSelect}
            defaultValue={"default"}
            onChange={(e) => findVoice(e.target.value, "voicemaker")} // find the object with pk value of value and set current voice
          >
            <Option label="None selected" value="default" disabled={true} />
            {voiceMakersAvalible.map((voiceMakerAvalible) => {
              return (
                <Option
                  label={voiceMakerAvalible.name}
                  value={voiceMakerAvalible.pk}
                  key={voiceMakerAvalible.pk}
                />
              );
            })}
          </Select>
        </>
      )}
      {elevenLabsAvalible && (
        <>
          <h1>ElevenLabs voices avalible:</h1>
          <Select
            ref={elevenlabsSelect}
            defaultValue={"default"}
            onChange={(e) => findVoice(e.target.value, "elevenlabs")} // find the object with pk value of value and set current voice
          >
            <Option label="None selected" value="default" disabled={true} />
            {elevenLabsAvalible.map((elevenLabAvalible) => {
              return (
                <Option
                  label={elevenLabAvalible.name}
                  value={elevenLabAvalible.pk}
                  key={elevenLabAvalible.pk}
                />
              );
            })}
          </Select>
        </>
      )}
      {elevenLabsAvalible && voiceProvider === "elevenlabs" && !dataToCheck && (
        <>
          <h4>Stability: / default {activeVoice.default_stability * 100}%</h4>
          {stability + "%"}
          <ElevenLabsProperties
            key={1}
            type="range"
            min="0"
            max="100"
            value={stability}
            onChange={(e) => setStability(e.target.value)}
            steps={1}
          />
          <h4>
            Similarity: / default {activeVoice.default_similarity_boost * 100}%
          </h4>
          {similarity + "%"}
          <ElevenLabsProperties
            key={2}
            type="range"
            min="0"
            max="100"
            value={similarity}
            onChange={(e) => setSimilarity(e.target.value)}
            steps={1}
          />
          <h4>Style: / default {activeVoice.default_style * 100}%</h4>
          {style + "%"}
          <ElevenLabsProperties
            key={3}
            type="range"
            min="0"
            max="100"
            value={style}
            onChange={(e) => setStyle(e.target.value)}
            steps={1}
          />
          <h4>Boost:</h4>
          <BoostCheckBox
            type="checkbox"
            checked={boost}
            onChange={() => setBoost(!boost)}
          />
        </>
      )}
      {creatorOrAutomation === "A" && (
        <>
          <Button onClick={() => preAutomationTTSHandler()}>
            Add no audio voice representations
          </Button>
          <Button onClick={() => checkForNoAudio()}>
            Give me another text with no audio!
          </Button>
        </>
      )}
    </>
  );
};

export default PreEdit;
