import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  editorData,
  createProTextAudio,
  elevenlabsTTS,
  acceptTextToSpeech,
  deleteTextToSpeech,
  noAudioRepresentation,
  preAutomationTTS,
  deleteAudioFileOnly,
} from "../../ENDPOINTS";
import { themeBlue } from "../../colors";
import PreEdit from "../../components/PreEdit";
import PostEdit from "../../components/PostEdit";
import Notification from "../../components/Notification";

const PreContainer = styled.div``;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 100px;
  background-color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextRequestWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  border: solid 1px ${(props) => (props.success ? "lightgreen" : "white")};
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CopyTextSection = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const CopyTextButton = styled.button`
  color: ${themeBlue};
`;

const Audio = styled.audio`
  padding: 5px;
  margin: 10px;
`;

const Input = styled.textarea`
  color: black;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  width: 90%;
`;

const Button = styled.button`
  padding: 10px;
  height: 50px;
  font-size: 20px;
  color: ${themeBlue};
`;

const EditorPro = () => {
  const { currentUser } = useSelector((state) => state);
  //
  const [voiceProvider, setVoiceProvider] = useState("");
  const [voicemakeAPIKey, setVoicemakerAPIKey] = useState("");
  //PreEdit
  const [creatorOrAutomation, setCreatorOrAutomation] = useState(null);
  const [gameTypes, setGameTypes] = useState();
  const [currentGameType, setCurrentGameType] = useState("default");
  const [activeVoice, setActiveVoice] = useState(null);
  const [questionOrAnswer, setQuestionOrAnswer] = useState(""); // Q or A
  const [playerInputAt, setPlayerInputAt] = useState(""); // S OR M OR E
  // PreEdit - elevenlabs
  const [stability, setStability] = useState();
  const [similarity, setSimilarity] = useState();
  const [style, setStyle] = useState();
  const [boost, setBoost] = useState();
  // Answer / Question (s / e)
  const [textToVoiceRequest1, setTextToVoiceRequest1] = useState(false);
  const [textToVoice1, setTextToVoice1] = useState("");
  const [textToDisplayUsers1, setTextToDisplayUsers1] = useState("");
  const [voiceOneUrl, setVoiceOneUrl] = useState("");
  // Question (m)
  const [textToVoiceRequest2, setTextToVoiceRequest2] = useState(false);
  const [textToVoice2, setTextToVoice2] = useState("");
  const [textToDisplayUsers2, setTextToDisplayUsers2] = useState("");
  const [voiceTwoUrl, setVoiceTwoUrl] = useState("");
  //PostEdit
  const [dataToCheck, setDataToCheck] = useState();
  //AUTOMATION 11LABS PROPS
  const [textInstancePk, setTextInstancePk] = useState(0);
  const [ttsInstancePk, setTtsInstancePk] = useState(0);
  //
  const navigate = useNavigate();

  const checkForNoAudio = async () => {
    try {
      const res = await axios.post(
        `${noAudioRepresentation}`,
        {
          voice_giver_pk: activeVoice.pk,
          game_type_pk: currentGameType,
          q_or_a: questionOrAnswer,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${currentUser.token}`,
          },
        }
      );
      console.log(res.data);
      if (res.data) {
        setPlayerInputAt(res.data.player_input_at);
        //
        setTextToVoice1(res.data.text_for_audio);
        setTextToVoice2(res.data.text_for_audio2);
        setTextToDisplayUsers1(res.data.text);
        setTextToDisplayUsers2(res.data.text2);
        setTextInstancePk(res.data.text_instance_pk);
        setTtsInstancePk(res.data.tts_instance_pk);
      }
    } catch (err) {}
  };

  const preAutomationTTSHandler = async () => {
    try {
      const res = await axios.post(
        `${preAutomationTTS}`,
        {
          voice_giver_pk: activeVoice.pk,
          game_type_pk: currentGameType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${currentUser.token}`,
          },
        }
      );
      console.log(res.data);
      if (res.data) {
      }
    } catch (err) {}
  };

  useEffect(() => {
    // CHECK IF IS EDITOR
    const checkIfEditor = async () => {
      try {
        const res = await axios.get(`${editorData}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${currentUser.token}`,
          },
        });
        console.log(res.data);
        if (res.data.access) {
          setGameTypes(res.data.game_types);
          console.log(res.data.voicemaker_api_key);
          setVoicemakerAPIKey(res.data.voicemaker_api_key);
        } else {
          navigate("/", { replace: true });
        }
      } catch (err) {
        navigate("/", { replace: true });
      }
    };
    checkIfEditor();
  }, []);

  const voicemakerRequest = async (textToVoice, part) => {
    if (part === "one") {
      setVoiceOneUrl("");
    }
    if (part === "two") {
      setVoiceTwoUrl("");
    }
    const res = await axios.post(
      "https://developer.voicemaker.in/voice/api",
      {
        Engine: activeVoice.Engine,
        VoiceId: activeVoice.VoiceId,
        LanguageCode: activeVoice.LanguageCode,
        Text: textToVoice,
        OutputFormat: activeVoice.OutputFormat,
        SampleRate: activeVoice.SampleRate,
        Effect: activeVoice.Effect,
        MasterSpeed: activeVoice.MasterSpeed,
        MasterVolume: activeVoice.MasterVolume,
        MasterPitch: activeVoice.MasterPitch,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${voicemakeAPIKey}`,
        },
      }
    );
    console.log(res.data);
    console.log("Success: ", res.data.success);
    console.log("Path: ", res.data.path);
    if (part === "one") {
      setVoiceOneUrl(res.data.path);
      setTextToVoiceRequest1(res.data.success);
    }
    if (questionOrAnswer === "Q" && playerInputAt === "M" && part === "two") {
      setVoiceTwoUrl(res.data.path);
      setTextToVoiceRequest2(res.data.success);
    }
  };
  const copyText = (where) => {
    if (where === "down") {
      setTextToDisplayUsers1(textToVoice1);
    } else if (where === "up") {
      setTextToVoice1(textToDisplayUsers1);
    }
  };
  const copyTextPartTwo = (where) => {
    if (where === "down") {
      setTextToDisplayUsers2(textToVoice2);
    } else if (where === "up") {
      setTextToVoice2(textToDisplayUsers2);
    }
  };
  const requestToSaveData = async () => {
    if (creatorOrAutomation === "C") {
      var TTS = 0;
      var TEXT = 0;
    } else if (creatorOrAutomation === "A") {
      var TTS = ttsInstancePk;
      var TEXT = textInstancePk;
    }
    const res = await axios.post(
      `${createProTextAudio}`,
      {
        data: {
          game_type: currentGameType,
          voice_by: activeVoice.pk,
          questionOrAnswer: questionOrAnswer,
          text_instance_pk: TEXT,
          tts_instance_pk: TTS,
          playerInputAt: playerInputAt,
          text_one: textToDisplayUsers1,
          text_two: textToDisplayUsers2,
          text_for_audio_one: textToVoice1,
          text_for_audio_two: textToVoice2,
          mp3_url_one: voiceOneUrl,
          mp3_url_two: voiceTwoUrl,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${currentUser.token}`,
        },
      }
    );
    console.log(res.data);
    setDataToCheck(res.data);
  };

  const giveAudioToVoicemakerVoice = async () => {
    if (creatorOrAutomation === "C") {
      var TTS = 0;
      var TEXT = 0;
    } else if (creatorOrAutomation === "A") {
      var TTS = ttsInstancePk;
      var TEXT = textInstancePk;
    }
    const res = await axios.post(
      `${createProTextAudio}`,
      {
        data: {
          game_type: currentGameType,
          voice_by: activeVoice.pk,
          questionOrAnswer: questionOrAnswer,
          playerInputAt: playerInputAt,
          text_instance_pk: TEXT,
          tts_instance_pk: TTS,
          text_one: textToDisplayUsers1,
          text_two: textToDisplayUsers2,
          text_for_audio_one: textToVoice1,
          text_for_audio_two: textToVoice2,
          mp3_url_one: voiceOneUrl,
          mp3_url_two: voiceTwoUrl,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${currentUser.token}`,
        },
      }
    );
    console.log(res.data);
    setDataToCheck(res.data);
  };

  const elevenlabsTTsRequest = async () => {
    if (creatorOrAutomation === "C") {
      var TTS = 0;
      var TEXT = 0;
    } else if (creatorOrAutomation === "A") {
      var TTS = ttsInstancePk;
      var TEXT = textInstancePk;
    }
    const res = await axios.post(
      `${elevenlabsTTS}`,
      {
        data: {
          game_type: currentGameType,
          voice_by: activeVoice.pk,
          questionOrAnswer: questionOrAnswer,
          playerInputAt: playerInputAt,
          text_instance_pk: TEXT,
          tts_instance_pk: TTS,
          text_one: textToDisplayUsers1,
          text_two: textToDisplayUsers2,
          text_for_audio_one: textToVoice1,
          text_for_audio_two: textToVoice2,
          stability: stability / 100,
          similarity: similarity / 100,
          style: style / 100,
          boost: boost,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${currentUser.token}`,
        },
      }
    );
    console.log(res.data);
    setDataToCheck(res.data);
  };

  const clearData = (e) => {
    e.preventDefault();
    setDataToCheck(null);
    setTextToDisplayUsers1("");
    setTextToDisplayUsers2("");
    setTextToVoice1("");
    setTextToVoice2("");
    setVoiceOneUrl("");
    setVoiceTwoUrl("");
  };

  const acceptInput = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${acceptTextToSpeech}`,
        {
          instanceId: dataToCheck.text_pk,
          q_or_a: dataToCheck.q_or_a,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${currentUser.token}`,
          },
        }
      );
      console.log(res.data);
      setDataToCheck(null);
      setTextToDisplayUsers1("");
      setTextToDisplayUsers2("");
      setTextToVoice1("");
      setTextToVoice2("");
      setVoiceOneUrl("");
      setVoiceTwoUrl("");
    } catch (err) {
      console.log(err);
    }
  };

  const deleteAudioFile = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.delete(
        `${deleteAudioFileOnly}${questionOrAnswer}/${dataToCheck.audio_pk}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${currentUser.token}`,
          },
        }
      );
      setDataToCheck(null);
      setVoiceOneUrl("");
      setVoiceTwoUrl("");
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const deleteInput = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.delete(
        `${deleteTextToSpeech}${questionOrAnswer}/${dataToCheck.text_pk}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${currentUser.token}`,
          },
        }
      );
      setDataToCheck(null);
      setVoiceOneUrl("");
      setVoiceTwoUrl("");
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PreContainer>
      <Container>
        {/* <Notification /> */}
        <PreEdit
          gameTypes={gameTypes}
          currentGameType={currentGameType}
          setCurrentGameType={setCurrentGameType}
          setActiveVoice={setActiveVoice}
          voiceProvider={voiceProvider}
          questionOrAnswer={questionOrAnswer}
          setQuestionOrAnswer={setQuestionOrAnswer}
          setPlayerInputAt={setPlayerInputAt}
          playerInputAt={playerInputAt}
          setVoiceProvider={setVoiceProvider}
          activeVoice={activeVoice}
          stability={stability}
          setStability={setStability}
          setSimilarity={setSimilarity}
          style={style}
          setStyle={setStyle}
          boost={boost}
          setBoost={setBoost}
          similarity={similarity}
          dataToCheck={dataToCheck}
          token={currentUser.token}
          preAutomationTTSHandler={preAutomationTTSHandler}
          creatorOrAutomation={creatorOrAutomation}
          setCreatorOrAutomation={setCreatorOrAutomation}
          checkForNoAudio={checkForNoAudio}
        />
        {!dataToCheck && (
          <TextRequestWrapper success={textToVoiceRequest1}>
            <TextWrapper>
              <h3>Text to speech</h3>
              <Input
                onChange={(e) => setTextToVoice1(e.target.value)}
                value={textToVoice1}
              />
              <CopyTextSection>
                <CopyTextButton onClick={() => copyText("down")}>
                  Copy down
                </CopyTextButton>{" "}
                <h3>Text to display user</h3>
                <CopyTextButton onClick={() => copyText("up")}>
                  Copy up
                </CopyTextButton>
              </CopyTextSection>
              <Input
                onChange={(e) => setTextToDisplayUsers1(e.target.value)}
                value={textToDisplayUsers1}
              />
            </TextWrapper>
            {voiceOneUrl && (
              <Audio controls={true}>
                <source type="audio/mp3" src={voiceOneUrl} />
              </Audio>
            )}
            {voiceProvider === "voicemaker" && (
              <Button onClick={() => voicemakerRequest(textToVoice1, "one")}>
                Voicemaker request
              </Button>
            )}
            {!voiceProvider && <Button>Select voice provider</Button>}
          </TextRequestWrapper>
        )}
        {questionOrAnswer === "Q" && playerInputAt === "M" && !dataToCheck && (
          <TextRequestWrapper success={textToVoiceRequest2}>
            <TextWrapper>
              <h3>Text to speech</h3>
              <Input
                onChange={(e) => setTextToVoice2(e.target.value)}
                value={textToVoice2}
              />
              <CopyTextSection>
                <CopyTextButton onClick={() => copyTextPartTwo("down")}>
                  Copy down
                </CopyTextButton>{" "}
                <h3>Text to display user</h3>
                <CopyTextButton onClick={() => copyTextPartTwo("up")}>
                  Copy up
                </CopyTextButton>
              </CopyTextSection>
              <Input
                onChange={(e) => setTextToDisplayUsers2(e.target.value)}
                value={textToDisplayUsers2}
              />
            </TextWrapper>
            {voiceTwoUrl && (
              <Audio controls={true}>
                <source type="audio/mp3" src={voiceTwoUrl} />
              </Audio>
            )}
            {voiceProvider === "voicemaker" && (
              <Button onClick={() => voicemakerRequest(textToVoice2, "two")}>
                Voicemaker request
              </Button>
            )}
            {/* {!voiceProvider && <Button>Select voice provider</Button>} */}
          </TextRequestWrapper>
        )}
        {voiceProvider === "elevenlabs" && !dataToCheck && (
          <Button onClick={() => elevenlabsTTsRequest(textToVoice2, "two")}>
            ELEVEN LABS request
          </Button>
        )}
        {voiceProvider === "voicemaker" && (
          <>
            {creatorOrAutomation === "C" && (
              <Button onClick={() => requestToSaveData()}>
                Save data at the server
              </Button>
            )}
            {creatorOrAutomation === "A" && (
              <Button onClick={() => giveAudioToVoicemakerVoice()}>
                Save audio at the server
              </Button>
            )}
          </>
        )}
        {dataToCheck && (
          <PostEdit
            dataToCheck={dataToCheck}
            voiceProvider={voiceProvider}
            acceptInput={acceptInput}
            deleteInput={deleteInput}
            deleteAudioFile={deleteAudioFile}
            creatorOrAutomation={creatorOrAutomation}
            clearData={clearData}
          />
        )}
      </Container>
    </PreContainer>
  );
};

export default EditorPro;
