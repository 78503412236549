import React from "react";
import styled from "styled-components";
import { themeAccentDark, themeBlue, themeBronze } from "../colors";

const Wrapper = styled.div`
  padding: 10px;
  width: 100%;
  border-bottom: solid ${themeBronze} 1px;
  :hover {
    outline: none !important;
    border-color: ${themeBlue};
    background-color: ${themeAccentDark};
    transition: 0.3s;
    transition-timing-function: ease-in-out;
  }
  cursor: pointer;
`;

const Author = styled.h4`
  padding: 0px 5px 5px 5px;
  color: ${themeBlue};
`;

const Text = styled.p`
  padding: 0px 5px 5px 5px;
`;

const PointsFrom = styled.p`
  padding: 0px 5px 0px 5px;
  color: ${themeBronze};
`;

const Results = ({ results }) => {
  return (
    <>
      {results.map((answer) => {
        return (
          <Wrapper key={answer.author_pk}>
            <Author>
              {answer.author} + {answer.points}
            </Author>
            <Text>{answer.text}</Text>
            <PointsFrom>
              {answer.points_from.map((username) => username).join(", ")}
            </PointsFrom>
          </Wrapper>
        );
      })}
    </>
  );
};

export default Results;
