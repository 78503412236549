import React from "react";
import styled from "styled-components";
import { themeBlue } from "../colors";
import { BASIC_URL } from "../ENDPOINTS";

const Button = styled.button`
  padding: 10px;
  height: 50px;
  font-size: 20px;
  color: ${themeBlue};
`;

const Audio = styled.audio`
  padding: 5px;
  margin: 10px;
`;

const PostEdit = ({
  dataToCheck,
  voiceProvider,
  acceptInput,
  deleteInput,
  deleteAudioFile,
  creatorOrAutomation,
  clearData,
}) => {
  return (
    <div>
      <h1>PostEdit</h1>
      {voiceProvider === "elevenlabs" && (
        <>
          <h3>{dataToCheck.provider}</h3>
          <h3>{dataToCheck.author}</h3>
          <h3>{dataToCheck.url}</h3>
          <h3>{dataToCheck.url2}</h3>
          <h3>{dataToCheck.voicegiver}</h3>
          <h3>{dataToCheck.pk}</h3>
          {dataToCheck.url && (
            <>
              <h3>Part One</h3>
              <Audio controls={true}>
                <source type="audio/mp3" src={BASIC_URL + dataToCheck.url} />
              </Audio>
            </>
          )}
          {dataToCheck.url2 && (
            <>
              <h3>Part Two</h3>
              <Audio controls={true}>
                <source type="audio/mp3" src={BASIC_URL + dataToCheck.url2} />
              </Audio>
            </>
          )}
        </>
      )}
      {voiceProvider === "voicemaker" && (
        <>
          <h3>{dataToCheck.provider}</h3>
          <h3>{dataToCheck.author}</h3>
          <h3>{dataToCheck.url}</h3>
          <h3>{dataToCheck.voicegiver}</h3>
          <h3>{dataToCheck.text_pk}</h3>
          <h3>{dataToCheck.audio_pk}</h3>
        </>
      )}
      {creatorOrAutomation === "C" && (
        <>
          <Button onClick={acceptInput}>Accept</Button>

          <Button onClick={deleteInput}>Delete</Button>
        </>
      )}
      {creatorOrAutomation === "A" && (
        <>
          <Button onClick={clearData}>GOT IT!</Button>
          {dataToCheck.provider === "elevenlabs" && (
            <Button onClick={deleteAudioFile}>Redo Audio</Button>
          )}
        </>
      )}
    </div>
  );
};

export default PostEdit;
