import React from "react";
import styled from "styled-components";
import { themeAccentDark, themeBlue, themeBronze } from "../colors";

const Answer = styled.p`
  padding: 10px;
  width: 100%;
  border-bottom: solid ${themeBronze} 1px;
  :hover {
    outline: none !important;
    border-color: ${themeBlue};
    background-color: ${themeAccentDark};
    transition: 0.3s;
    transition-timing-function: ease-in-out;
  }
  cursor: pointer;
`;

const GiveBest = ({ givenAnswers, playerPk, SendAction }) => {
  return (
    <>
      {givenAnswers.map((answer) => {
        if (playerPk === answer.author_pk) {
          return;
        } else {
          return (
            <Answer
              key={answer.author_pk}
              onClick={() =>
                SendAction(
                  { author_pk: answer.author_pk, from_player_pk: playerPk },
                  "submit_answer_v2"
                )
              }
            >
              {answer.text}
            </Answer>
          );
        }
      })}
    </>
  );
};

export default GiveBest;
