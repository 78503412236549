import { languageRequest } from "./ENDPOINTS";
import axios from "axios";

export const langRequest = async (page) => {
  var page_lang_version = window.localStorage.getItem("PAGE_LANG_VERSION");
  //console.log("PAGE_LANGUAGE_VERSION", page_lang_version);
  if (page_lang_version === null) {
    try {
      const res = await axios.post(
        `${languageRequest}`,
        {
          page,
          page_lang_version,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
          },
        }
      );
      window.localStorage.setItem("PAGE_LANG_VERSION", res.data.version);
      window.localStorage.setItem(
        `${page}_page_language_data`,
        JSON.stringify(res.data.data)
      );
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  } else if (page_lang_version !== null) {
    try {
      const res = await axios.post(
        `${languageRequest}`,
        {
          page,
          page_lang_version,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
          },
        }
      );
      if (page_lang_version === res.data.version) {
        //console.log("ALL GOOD");
        return window.localStorage.getItem(`${page}_page_language_data`);
      } else {
        //console.log("I SHOULD GET NEW VERSION AND DATA");
        window.localStorage.setItem("PAGE_LANG_VERSION", res.data.version);
        window.localStorage.setItem(
          `${page}_page_language_data`,
          JSON.stringify(res.data.data)
        );
        return res.data.data;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
  return;
};
