import React, { useState } from "react";
import styled from "styled-components";
import { themeDark } from "../colors";

const Container = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  border: solid 1px grey;
  border-radius: 15px;
  padding: 6px;
  background-color: ${themeDark};
  margin-bottom: 10px;
`;

const SelectOptions = styled.button`
  padding: 10px;
  border: solid 1px grey;
  border-radius: 5px;
  background-color: ${themeDark};
  margin: 5px;
  &.select {
    border-color: ${(props) => (props.error ? "red" : "grey")};
    //display: ${(props) => (props.openOptions ? "none" : "block")};
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${(props) => (props.openOptions ? "1fr" : "0fr")};
  overflow: hidden;
  background-color: ${themeDark};
  transition: grid-template-rows 200ms;
`;

const Expandable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeDark};
  min-height: 0px;
  color: white;
`;

const GameOptions = ({
  gameTypeOptions,
  selectedGameOption,
  SendAction,
  playerPk,
  handleGetVoicesAvalible,
  noGameModeError,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  return (
    <Container>
      <SelectOptions
        openOptions={openOptions}
        className="select"
        error={noGameModeError}
        onClick={() => {
          setOpenOptions(!openOptions);
        }}
      >
        {selectedGameOption ? (
          <p>Game: {selectedGameOption}</p>
        ) : (
          <p>Select Game</p>
        )}
      </SelectOptions>
      <Wrapper openOptions={openOptions}>
        <Expandable>
          {gameTypeOptions.map((option) => {
            return (
              <SelectOptions
                key={option.pk}
                onClick={() => {
                  setOpenOptions(!openOptions);
                  SendAction(
                    {
                      game_type_pk: option.pk,
                      action_by: playerPk.current,
                    },
                    "set_game_type"
                  );
                  handleGetVoicesAvalible(option.pk);
                }}
              >
                {option.name}
              </SelectOptions>
            );
          })}
        </Expandable>
      </Wrapper>
    </Container>
  );
};

export default GameOptions;
