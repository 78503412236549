import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { mobile } from "../../responsive";
import Game from "./Game";
import {
  themeAccentDark,
  themeBlue,
  themeBronze,
  themeDark,
  themeDark2,
  themeLighterDard,
} from "../../colors";
import { allowMeToJoin, prejoin } from "../../ENDPOINTS";
import axios from "axios";
import { langRequest } from "../../translationRequest";
import { useLocation, useParams } from "react-router-dom";

const Container = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  background-color: ${themeLighterDard};
`;

const Wrapper = styled.div`
  overflow: auto;
  background-color: ${themeDark};
  min-width: 700px;
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile({
    paddingTop: "100px",
    minWidth: "100%",
    overflow: "auto",
    display: "block",
  })}
`;

const XD = styled.div`
  background-color: grey;
`;

const Button = styled.button`
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 90%;
  font-size: 18px;
  border: 1px solid ${themeAccentDark};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  ${mobile({ width: "65%" })}
`;

const Input = styled.input`
  text-align: center;
  background-color: ${themeDark2};
  border-radius: 50px;
  padding: 20px;
  margin: 20px;
  width: 80%;
  font-size: 16px;
  border: 1px solid ${(props) => props.borderColor};
  :focus {
    outline: none !important;
    border: 1px solid ${themeBlue};
    box-shadow: 0 0 10px ${themeBlue};
  }
  :hover {
    outline: none !important;
    border: 1px solid ${themeBronze};
    box-shadow: 0 0 10px ${themeBronze};
  }
  ${mobile({ width: "60%" })}
`;

const PreConnect = () => {
  const location = useLocation();
  const params = useParams();

  const { currentUser } = useSelector((state) => state);
  const [nickname, setNickname] = useState("");
  const [guestAuthToken, setGuestAuthToken] = useState("");
  const [canConnect, setCanConnect] = useState("");
  useState(""); // MOSTLY FOR GUEST USER DATA
  //WE CHECK IF WE HAVE guest_game_auth_token and username
  //
  const array = ["ready", "password", "loading", "banned", "started"];

  useEffect(() => {
    const langData = langRequest("preconnect");
    langData.then((val) => {
      console.log(val);
    });
  }, []);

  useEffect(() => {
    // THIS IS FOR A REDIRECT FROM GAME CREATE@!
    if (currentUser === null && location.state !== null) {
      setCanConnect(location.state.GuestCreator);
    }
  });

  const AreYouAllowedToJoinTheGame = async () => {
    var auth_token = null;
    if (currentUser != null) {
      auth_token = `token ${currentUser.token}`;
    }
    try {
      const res = await axios.post(
        `${allowMeToJoin}`,
        {
          nickname: nickname,
          guestAuthToken: guestAuthToken,
          gamePk: params.slug,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
            Authorization: auth_token,
          },
        }
      );
      console.log(res.data);
      if (res.data.response_on_join_error) {
        //set error
        console.log(res.data.response_on_join_error);
      }
      if (res.data.give_password) {
        setCanConnect("password");
      }
      if (res.data.canconnect) {
        setCanConnect("ready");
      }
      setCanConnect(res.data.canconnect);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePreConnectRequest = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${prejoin}`,
        {
          nickname: nickname,
          guestAuthToken: guestAuthToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept-Language": "pl",
          },
        }
      );
      console.log(res.data);
      window.localStorage.setItem(
        "guest_game_auth_token",
        res.data.guest_game_auth_token
      );
      window.localStorage.setItem("game_nickname", res.data.nickname);
      setGuestAuthToken(res.data.guest_game_auth_token);
      setNickname(res.data.nickname);
      AreYouAllowedToJoinTheGame();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser !== null) {
      setNickname(currentUser.user.username);
      AreYouAllowedToJoinTheGame();
    } else {
      var guestToken = window.localStorage.getItem("guest_game_auth_token");
      var lastTimeUsedNickname = window.localStorage.getItem("game_nickname");
      setGuestAuthToken(guestToken);
      setNickname(lastTimeUsedNickname);
    }
  }, []);

  return (
    <>
      {canConnect === "ready" && nickname !== "" ? (
        <Game guestAuthToken={guestAuthToken} nickname={nickname} />
      ) : (
        <Container>
          {/* display={canConnect === "ready" && "none"} */}
          <Wrapper>
            {/* RECONNECT! - started and user in request is in game */}
            {canConnect !== "ready" && (
              <>
                <Input
                  onChange={(e) => setNickname(e.target.value)}
                  value={nickname}
                  placeholder="Nickname"
                />
                <Button onClick={(e) => handlePreConnectRequest(e)}>
                  JOIN
                </Button>
              </>
            )}
            {canConnect == "password" && <XD>give password</XD>}
            {canConnect == "loading" && <XD>waiting for server response</XD>}
            {canConnect == "banned" && <XD>owner banned you from the game</XD>}
            {canConnect == "already_connected" && <XD>already connected</XD>}
            {canConnect == "started" && (
              <XD>game already started, wait till it ends to join</XD>
            )}
          </Wrapper>
        </Container>
      )}
    </>
  );
};

export default PreConnect;
