import React, { useState } from "react";
import styled from "styled-components";
import { themeAccentDark, themeBlue, themeDark } from "../colors";
import { BASIC_URL } from "../ENDPOINTS";

const Container = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  border: solid 1px grey;
  border-radius: 15px;
  padding: 6px;
  background-color: ${themeDark};
`;

const SelectOptions = styled.button`
  padding: 10px;
  border: solid 1px grey;
  border-radius: 5px;
  background-color: ${themeDark};
  margin: 5px;
  border-color: ${(props) => (props.error ? "red" : "grey")};
  &.select {
    //display: ${(props) => (props.openOptions ? "none" : "block")};
  }
  &.voice {
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: ${(props) => (props.openOptions ? "1fr" : "0fr")};
  overflow: hidden;
  background-color: ${themeDark};
  transition: grid-template-rows 200ms;
`;

const Expandable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeDark};
  min-height: 0px;
  color: white;
`;
const Image = styled.img`
  width: 256px;
  height: 256px;
  border-radius: 5px;
`;
const VoiceOptions = ({
  voices,
  SendAction,
  playerPk,
  selectedVoiceOption,
  noVoiceError,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  return (
    <Container>
      <SelectOptions
        openOptions={openOptions}
        className="select"
        error={noVoiceError}
        onClick={() => {
          setOpenOptions(!openOptions);
        }}
      >
        {selectedVoiceOption ? (
          <p>Voice: {selectedVoiceOption}</p>
        ) : (
          <p>Select Voice</p>
        )}
      </SelectOptions>
      <Wrapper openOptions={openOptions}>
        <Expandable>
          {voices.map((voice) => {
            return (
              <SelectOptions
                key={voice.pk}
                onClick={() => {
                  setOpenOptions(!openOptions);
                  SendAction(
                    {
                      voice_pk: voice.pk,
                      action_by: playerPk.current,
                    },
                    "set_game_voice"
                  );
                }}
              >
                <Image src={BASIC_URL + voice.image_url} />
                <h2>{voice.name}</h2>
              </SelectOptions>
            );
          })}
        </Expandable>
      </Wrapper>
    </Container>
  );
};

export default VoiceOptions;
