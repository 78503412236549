import React from "react";
import App from "./App";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Login from "./pages/auth/Login";
import { useSelector } from "react-redux";
import Register from "./pages/auth/Register";
import Create from "./pages/game/Create";
import PreConnect from "./pages/game/PreConnect";
import EditorPro from "./pages/editor/EditorPro";

const Router = () => {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state);

  return (
    <div>
      {/* #g = GAME so we dont display normal game */}
      {location.hash !== "#g" && <Navbar />}
      {/*            === "#g" <Navbar /> : <InGameNavbar /> */}
      <Routes>
        <Route path="/" element={<App />} />
        <Route
          path="login/"
          element={currentUser ? <Navigate replace to="/" /> : <Login />}
        />
        <Route
          path="register/"
          element={currentUser ? <Navigate replace to="/" /> : <Register />}
        />

        {/* GAME */}
        <Route path="create/" element={<Create />} />
        <Route path="game/:slug/" element={<PreConnect />} />
        <Route
          path="editorpro/"
          element={currentUser ? <EditorPro /> : <Navigate to="/login/" />}
        />

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </div>
  );
};

export default Router;
